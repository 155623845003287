import { Form } from '@formio/react';
import axios from 'axios';
import React, { useEffect } from 'react';
import '@iframe-resizer/child'
import "bootstrap/dist/css/bootstrap.min.css"
import "@formio/js/dist/formio.form.min.css"
// import ContactDetails from './ContactDetails';

const BaseURL = 'https://api.hummz.net/v2';
// const BaseURL = 'http://localhost:3000/v2';
const prod = process.env.NODE_ENV === 'production';

const FIOForm = (props) => {
	const {
		url = BaseURL,
		formSlug = '',
		title = '',
		orderTypeId = '',
		hasPayment = false,
		successMessage = '<h1 class="text-center text-dark" >Thank you!</h1><h3 class="text-center text-dark">For any queries or additional information contact contact at +91-9818568825; +91-9873556395 or write to us at info@aquafoundation.in.</h3>',
		errorMessage = '<h1 class="text-center text-dark">Unfortunately we were unable to process your submission.</h1><h3 class="text-center text-dark">Please contact at +91-9818568825; +91-9873556395 or write to us at info@aquafoundation.in.</h3>',
		paymentSuccessMessage = '<h1 class="text-center text-dark" >Thank you!</h1><h3 class="text-center text-dark">We have received your payment.</h3><h3 class="text-center text-dark">For any queries or additional information contact contact at +91-9818568825; +91-9873556395 or write to us at info@aquafoundation.in.</h3>',
		paymentFailureMessage = '<h1 class="text-center text-dark">Thank you for your interest!</h1><h3 class="text-center text-dark"> Unfortunately we were unable to process your payment. Please contact at +91-9818568825; +91-9873556395 or write to us at info@aquafoundation.in to complete the payment.</h3>',
		paymentProcessingMessage = '<h1 class="text-center text-dark">Thank you!</h1><h3 class="text-center text-dark">Thank you for registering. We shall confirm your participation upon receipt of payment. For payment details, queries or additional information please contact at +91-9818568825; +91-9873556395 or write to us at info@aquafoundation.in.</h3>',
		customStyle = '',
	} = props

	const orderType = props.orderType === 'e' ? 'event' : props.orderType === 'p' ? 'product' : '';
	const [formSchema, setFormSchema] = React.useState(props.formSchema);
	const [formData, setFormData] = React.useState({});	
	const [order, setOrder] = React.useState({});
	const [paymentSuccessful, setPaymentSuccessful] = React.useState(false);
	const [formSubmit, setFormSubmit] = React.useState('');
	const [location, setLocation] = React.useState(null);
	const [submission, setSubmission] = React.useState({ data: {}, metadata: {} });

	useEffect(() => {
		if (!formSchema) {
			axios.get(`${url}/forms/schema/${formSlug}`)
				.then((response) => {
					// console.log(response);
					let formSchema = response.data;
					formSchema = JSON.parse(JSON.stringify(formSchema).replace('{{url}}', url));
					setFormSchema(formSchema);
				})
				.catch((error) => {
					console.error(error);
				})
		}

		// inject razorpay
		const script = document.createElement("script");
		script.src = "https://checkout.razorpay.com/v1/checkout.js";
		script.async = true;
		document.body.appendChild(script);

		getClientIp();
	})

	const getClientIp = async () => {
		// get client ip address and save in state
		if (window.location.host.indexOf('localhost') < 0) {
			let ipresponse = await fetch("https://api.hummz.net/v2/countries/ip2location")
			let location = await ipresponse.json();
			// console.log(location);
			setLocation(location);
		} else {
			// console.log('Skipping ip address for localhost');

		}
	}

	const buttonEvent = (event) => {
		// console.log(event);
	}

	const onNextPage = (submission) => {
		if ("parentIframe" in window) {
		window.parentIframe.resize();
		}
	}

	const onPrevPage = (submission) => {
		if ("parentIframe" in window) {
		window.parentIframe.resize();
		}
	}

	const onFormSubmit = (submission) => {
		console.log(submission);
		let _formData = {};
		_formData.data = submission.data;
		_formData.meta = { formSlug: formSlug };
		_formData.meta.metadata = submission.metadata;
		_formData.meta.metadata.ip = location?.query || '';
		_formData.meta.metadata.location = location;
		_formData.meta.metadata.pathName = _formData.meta.metadata.pathName || window.location.href || '';
		// console.log('update 1')
		delete _formData.data.submit;
		// console.log('update 2')
		if (hasPayment) {
			// console.log('update 3')
			_formData.data.currency = _formData.data.amount.substr(0, 3);
			_formData.data.amount = parseFloat(_formData.data.amount.substr(3));
			_formData.data.taxAmount = parseFloat(_formData.data.taxAmount.substr(3));
			_formData.data.totalAmount = parseFloat(_formData.data.totalAmount.substr(3));
			// console.log('update 4')
		}
		// console.log('update 5')
		setFormData(_formData);

		// console.log("update 6 - hasPayment", hasPayment, _formData.data.paymentMode);
		if (hasPayment) {
			// create order and handle payment
			// console.log('Will create order and handle payment');
			// Save the form data in the form response
			axios(`${url}/forms/response/${formSlug}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				data: _formData
			}).then((response) => {}).catch((error) => {console.log(error);});
			createOrder(_formData);
		} else {
			// submit form to the server
			// console.log('The form was just submitted!!!', _formData);

			axios(`${url}/forms/response/${formSlug}`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json'
				},
				data: _formData
			})
				.then((response) => {
					// console.log('resolving successfully', response.data);
					setFormSubmit('success');
					window.location.href = "#thankyou";

					//sectionFormModalToggle();
					// var t = document.getElementById("registration_form"),
					// 	n = document.getElementById("register-success-confirm");
					// if (formData.participation !== 'confirm') {
					// 	n = document.getElementById("register-success");
					// }
					// t.classList.add("d-none"), n.classList.remove("d-none");
				})
				.catch((error) => {
					console.error(error);
					setFormSubmit('error');
				});
		}
	}

	const createOrder = async (formData) => {
		let productVariant = '';
		if (orderType === 'event') {
			if (formData.data?.delegateType && formData.data?.delegateType?.length > 0) {
				productVariant = formData.data.delegateType;
			}
		}
		// Check if the order already has an orderId
		// If yes, sent the same to retry the payment
		let order = {};
		// console.log("createorder -> formdata", formData);
		order.issueDate = (new Date()).toISOString();
		order.type = orderType;
		order.customer = {
			title: formData.data.title,
			firstname: formData.data.firstname,
			middlename: formData.data.middlename,
			lastname: formData.data.lastname,
			name: formData.data.title + ' ' + formData.data.firstname + ' ' + formData.data.middlename + ' ' + formData.data.lastname,
			email: formData.data.email,
			mobile: formData.data.mobile,
			address1: formData.data.address1 || '',
			address2: formData.data.address2 || '',
			city: formData.data.city || '',
			state: formData.data.state || '',
			postalcode: formData.data.postalcode || '',
			countryId: formData.data.countryId
		};
		order.items = [
			{
				sku: '',
				name: formSchema.title,
				hsnSac: '',
				quantity: 1,
				currency: formData.data.currency,
				unitPrice: formData.data.amount,
				unit: 'no',
				discount_percent: 0,
				discount_amount: 0,
				tax_percent: ((formData.data.taxAmount / formData.data.amount) * 100),
				tax_amount: formData.data.taxAmount,
				variant: productVariant,
				meta: {}
			}
		];
		order.totalQuantity = 1;
		order.currency = order.items[0].currency;
		order.totalDiscountAmount = 0;
		order.totalTaxAmount = order.items[0].tax_amount;
		order.totalAmount = order.items[0].unitPrice;
		order.totalNetAmount = order.totalAmount + order.totalTaxAmount;
		order.totalTaxableAmount = order.totalAmount;
		order.isSubscription = false;
		order.orderableId = orderTypeId;
		order.orderableType = orderType;
		order.subscriptionFrequency = '';
		order.subscriptionCount = 0;
		order.gst = {
		}
		order.meta = {
			metadata: formData.metadata,
			formData: formData.data,
			formSlug: formSlug
		}

		if (formData.data.paymentMode !== 'online') {
			order.status = 'processing';
		}

		axios(`${url}/orders/payment`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			data: order
		})
			.then((response) => {
				// console.log(response);
				let responseJson = response.data;
				// console.log('resolving successfully', responseJson);
				let orderId = responseJson.documentNumber;
				let orderRef = responseJson.orderReference[0].reference;
				order.orderId = orderId;
				order.orderRef = orderRef;
				setOrder(order);
				if (formData.data.paymentMode === 'online') {
					handlePayment(order, orderId, orderRef);
				} else {
					setPaymentSuccessful(false);
					setOrder({});
					setFormSubmit('processing');
				}

			})
			.catch((error) => {
				console.error(error);
			});
	}

	const handlePayment = (order, orderId, orderRef) => {
		let options = {
			key: prod ? "rzp_live_DaBZwF5h0IFzfR" : "rzp_test_EfnGW77z6PMPCo",
			amount: order.totalNetAmount * 100,
			currency: order.currency,
			order_id: orderRef,
			name: "Aqua Foundation",
			description: order.items[0].title,
			image: "https://cdn.humz.it/aquafoundation/afa-logo.png",
			handler: (response) => {
				// formData.meta.paymentId = response.razorpay_payment_id;
				// console.log('Payment received', response);
				if (orderId && orderId.length) {
					axios(url + '/orders/payment/' + orderId, {
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json'
						},
						data: { paymentDetail: response }
					})
						.then((response) => {
							// console.log('updated order complete', response.data);
							setPaymentSuccessful(true);
						})
						.catch((error) => {
							console.error(error);
						});
					setPaymentSuccessful(true);
					setOrder({});
					setFormSubmit('success');

				}
			},
			modal: {
				ondismiss: () => {
					// console.log('Checkout form closed');
					setPaymentSuccessful(false);
					setFormSubmit('aborted');
					axios(url + '/orders/payment/' + orderId, {
						method: 'PATCH',
						headers: {
							'Content-Type': 'application/json'
						},
						data: { paymentDetail: {}, status: 'aborted' }
					})
						.then((response) => {
							// console.log('updated order aborted', response.data);
							setPaymentSuccessful(false);

						})
						.catch((error) => {
							console.error(error);
						});
				}
			},
			prefill: {
				name: order.customer.firstname + ' ' + order.customer.middlename + ' ' + order.customer.lastname,
				email: order.customer.email,
				contact: order.customer.mobile,
			},
			notes: {
				slug: formSlug,
				type: orderType,
				name: order.customer.firstname + ' ' + order.customer.middlename + ' ' + order.customer.lastname,
				email: order.customer.email,
				phone: order.customer.mobile,
				country: order.customer.countryId
			},
			theme: {
				color: "#c4aea2"
			}
		};
		// console.log('Options', options);
		let rzp1 = new window.Razorpay(options);
		rzp1.open();
	}

	return (
		<div className={`mb-5 p-2 p-md-0 pb-5 ${customStyle}`}>
			<h4>{title}</h4>
			{(formSchema && formSubmit === '') &&
				<Form 
					form={formSchema}
					options={{ noAlerts: true, readOnly: false }} 
					submission={submission} 
					onNextPage={onNextPage}
					onPrevPage={onPrevPage}
					onCustomEvent={buttonEvent} 
					onSubmit={onFormSubmit} />
			}
			<p id="thankyou"></p>
			{formSubmit === 'success' && paymentSuccessful &&
				<div dangerouslySetInnerHTML={{ __html: paymentSuccessMessage }} className="bg-light shadow p-5 font-weight-bold" />
			}
			{formSubmit === 'success' &&
				<div dangerouslySetInnerHTML={{ __html: successMessage }} className="bg-light shadow p-5 font-weight-bold">
				</div>
			}
			{formSubmit === 'processing' &&
				<div dangerouslySetInnerHTML={{ __html: paymentProcessingMessage }} className="bg-light shadow p-5 font-weight-bold">
				</div>
			}
			{formSubmit === 'error' &&
				<div dangerouslySetInnerHTML={{ __html: errorMessage }} className="bg-light shadow p-5 font-weight-bold">
				</div>
			}
			{formSubmit === 'aborted' &&
				<div dangerouslySetInnerHTML={{ __html: paymentFailureMessage }} className="bg-light shadow p-5 font-weight-bold">
				</div>
			}
		</div>
	)
}

export default FIOForm;