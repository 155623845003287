import axios from "axios"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { FallingLines } from "react-loader-spinner"

import voidIllustration from './assets/void.svg'
import FIOForm from "./FIOForm"
import SIOForm from "./SIOForm"

import './App.css';
import { useLocation, useSearchParams } from "react-router-dom"

function App() {
  const [searchParams] = useSearchParams()
  const pathLocation = useLocation()
  const type = searchParams.get('t')
  const id = searchParams.get('i')
  const hasPayment = searchParams.get('p') === '1'
  const showFooter = searchParams.get('f') === '0' ? false : true 
  const [status, setStatus] = useState('loading')
  const [slug, setSlug] = useState(pathLocation.pathname.substring(1, pathLocation.pathname.length))
  const [schema, setSchema] = useState(null)
  const [theme, setTheme] = useState(null)
  const [location, setLocation] = useState(null)
  const [title, setTitle] = useState('Hummz Forms')

  const hostname = window.location.hostname;
  // const isProduction = (hostname === 'localhost' ? false : hostname === 'staging.hummz.app' ? false : true);
  const BaseURL = (hostname === 'localhost' ? 'http://localhost:3000/v2' : hostname === 'staging.hummz.app' ? 'https://apidev.hummz.net/v2' : 'https://api.hummz.net/v2');

  useEffect(() => {
    let ignore = false
    const controller = new AbortController();
    async function fetchSchema() {
      try {
        // console.log('fetching schema')
        // console.log('window.location', window.location)
        // const slug = window.location.pathname.substring(1, window.location.pathname.length)
        // console.log('slug', slug)
        // if (!ignore) setSlug(slug)
        if (slug) {
          const formResponse = await axios.get(`${BaseURL}/forms/schema/${slug}`)
          console.log('formResponse', formResponse, ignore)
          if (formResponse && !ignore) {
            setSchema(formResponse.data)
            setTitle(formResponse.data.title)
            if (formResponse.data.theme) {
              // console.log('theme', formResponse.data.theme)
              setTheme(formResponse.data.theme)
            }
            setStatus('loaded')
          } else if (!ignore) {
            // console.log('no response, setting invalid')
            setSchema(null)
            setTheme(null)
            setTitle('Hummz Forms')
            setStatus('invalid')
          }
        } else if (!ignore) {
          // console.log('no slug, setting invalid')
          setSlug('')
          setSchema(null)
          setTheme(null)
          setTitle('Hummz Forms')
          setStatus('invalid')
        } else {
          setStatus('invalid')
          // console.log('ignore set', ignore)
        }
      } catch (error) {
        console.error(error)
      }
    }
    // console.log('will fetch schema')
    if (!schema) {
      fetchSchema()
    }
    return () => { ignore = true; controller.abort(); }
  }, [slug, schema, title, theme, status])

  // useEffect(() => {
  //   let ignore = false
  //   const controller = new AbortController()
  //   async function fetchLocation() {
  //     const response = await axios.get('https://geolocation-db.com/json/');
  //     if (!ignore) setLocation(response.data)
  //   }
  //   if (window.location.hostname !== 'localhost' && !location) {
  //     fetchLocation()
  //   }
  //   return () => { ignore = true; controller.abort(); }
  // }, [location])

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {status === 'loading' && <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <FallingLines
          color="#4fa94d"
          width="100"
          visible={true}
          ariaLabel='falling-lines-loading'
        />
      </div>}
      {status === 'invalid' && <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img src={voidIllustration} alt='Form not found' height='50%' align='center' />
        <h2>Form not found</h2>
      </div>}
      <div className="w-100 d-flex justify-content-center">
        {(status === 'loaded' && schema.type === 'fio') ? <FIOForm url={BaseURL} formSchema={schema} theme={theme} formSlug={slug} orderType={type} orderTypeId={id} hasPayment={hasPayment} params={``} /> : <SIOForm schema={schema} theme={theme} slug={slug} />}
      </div>
      {/* <div className="footer">
        <small style={{ marginLeft: "20px" }}>Copyright &copy; {(new Date()).getFullYear()} Hummz</small>
        <a style={{ marginRight: "20px", textDecoration: "none", color: "#000000", textAlign: "center" }} href={`https://www.hummz.com/?utm_source=surveys&utm_medium=web&utm_campaign=${slug || 'hummz-forms'}`}><small>powered by</small><br/><img src="https://cdn.hummz.it/hummz/v3/logo-dark-sm.png" height="20" width="auto" alt="Powered by Hummz" /></a>
      </div> */}
      <div className={!showFooter ? 'd-none' : 'footer'}>
        <a href={`https://www.hummz.com/?utm_source=surveys&utm_medium=web&utm_campaign=${slug || 'hummz-forms'}`}>
          <img src="https://cdn.hummz.it/hummz/v3/favicon-dark-circle@1000x1000.png" align="left" height="20" width="20" alt="Hummz Logo" /> powered by hummz
        </a>
      </div>

    </div>
  );
}

export default App;

