import axios from "axios"
import React, { useEffect, useState } from "react"
import { Helmet } from "react-helmet"
import { FallingLines } from "react-loader-spinner"
import * as SurveyCore from "survey-core"
import { Survey } from "survey-react-ui"
import * as widgets from "surveyjs-widgets";
import Url from 'url-parse';

import voidIllustration from './assets/void.svg'

import './App.css';
import "pretty-checkbox/dist/pretty-checkbox.css";
import "survey-core/defaultV2.css"
import { Container } from "reactstrap"

SurveyCore.StylesManager.applyTheme("defaultV2")

function SIOForm(props) {
  const { schema, theme, slug } = props
  // const [loading, setLoading] = useState(true)
  const [status, setStatus] = useState('loading')
  // const [slug, setSlug] = useState('')
  const [location, setLocation] = useState(null)
  const [title, setTitle] = useState('Hummz Forms')
  const [timestamp, setTimestamp] = useState(new Date())
  const [model, setModel] = useState(null)

  useEffect(() => {
    let ignore = false
    const controller = new AbortController();
    async function fetchSchema() {
      try {
        // console.log('fetching schema')
        const slug = window.location.pathname.substring(1, window.location.pathname.length)
        // console.log('slug', slug)
        // if (!ignore) setSlug(slug)
        if (slug) {
          // const formResponse = await axios.get(`https://api.hummz.net/v2/forms/schema/${slug}`)
          // console.log('formResponse', formResponse, ignore)
          if (schema && !ignore) {
            let model = new SurveyCore.Model(schema)
            setModel(model)
            setTitle(schema.title)
            setTimestamp(new Date())
            if (theme) {
              console.log('theme', theme)
              model.applyTheme(theme)
            }
            setStatus('loaded')

            // check for saved responses
            // console.log('slug::', slug)
            doLoadState(model, slug)
          } else if (!ignore) {
            // console.log('no response, setting invalid')
            setModel(null)
            setTitle('Hummz Forms')
            setStatus('invalid')
          }
        } else if (!ignore) {
          // console.log('no slug, setting invalid')
          // setSlug('')
          setModel(null)
          setTitle('Hummz Forms')
          setStatus('invalid')
        } else {
          setStatus('invalid')
          // console.log('ignore set', ignore)
        }
      } catch (error) {
        console.error(error)
      }
    }
    // console.log('will fetch schema')
    if (!model) {
      fetchSchema()
    }

    // setup widgets
    widgets.prettycheckbox(SurveyCore);
    widgets.inputmask(SurveyCore);
    widgets.nouislider(SurveyCore);
    widgets.ckeditor(SurveyCore)
    return () => { ignore = true; controller.abort(); }
  }, [model, schema, theme])

  useEffect(() => {
    let ignore = false
    const controller = new AbortController()
    async function fetchLocation() {
      const response = await axios.get('https://geolocation-db.com/json/');
      if (!ignore) setLocation(response.data)
    }
    if (window.location.hostname !== 'localhost' && !location) {
      fetchLocation()
    }
    return () => { ignore = true; controller.abort(); }
  }, [location])

  const onUploadFiles = (_, options) => {
    let formData = new FormData()
    options.files.forEach((file) => {
      formData.append(file.name, file)
    })

  }

  const doSaveState = (_) => {
    // console.log("doSaveState: ", _.data);
    // console.log("New value: " + options);
    localStorage.setItem(`hmzform-${slug}`, JSON.stringify({ currentPageNo: _.currentPageNo, data: _.data }))
  }

  const doLoadState = (_, slug) => {
    // console.log('doLoadState', slug)
    const savedState = localStorage.getItem(`hmzform-${slug}`)
    // console.log('doLoadState', savedState)
    let savedSurvey = {}
    if (savedState) {
      savedSurvey = JSON.parse(savedState)
      // console.log('savedSurvey', savedSurvey)
      if (savedSurvey.currentPageNo) _.currentPageNo = savedSurvey.currentPageNo;
      if (savedSurvey.data) _.data = savedSurvey.data;
    }
  }

  const onComplete = async (submission) => {
    // console.log("Form completed! Results: " + JSON.stringify(submission.data));
    doSaveState(submission)

    let startTime = timestamp;
    let endTime = new Date();
    let url = new Url(window.location.href, true);
    // console.log("URL", url);
    const hostname = window.location.hostname;
    // const isProduction = (hostname === 'localhost' ? false : hostname === 'staging.hummz.app' ? false : true);
    const BaseURL = (hostname === 'localhost' ? 'http://localhost:3000/v3' : hostname === 'staging.hummz.app' ? 'https://apidev.hummz.net/v3' : 'https://api3.hummz.net/v3');
    // const BaseURL = "https://api3.hummz.net/v3"
    // console.log("Complete! ", result.data);

    let formResponse = {
      data: submission.data,
      meta: {
        formSlug: slug,
        formUrl: url,
        urlParams: url.query,
        location: location,
        startTime: startTime,
        endTime: endTime,
        formDuration: (endTime - startTime),
        metadata: {
          "timezone": (new window.Intl.DateTimeFormat().resolvedOptions().timeZone),
          "offset": (new Date()).getTimezoneOffset(),
          "referrer": document.referrer,
          "browserName": navigator.appName,
          "userAgent": navigator.userAgent,
          "pathName": window.location.href || '',
          "onLine": navigator.onLine,
        }
      }
    }

    const response = await axios({
      method: 'POST',
      url: `${BaseURL}/forms/${slug}/response`,
      headers: {
        'Content-Type': 'application/json',
        "app-id": "hummz-forms"
      },
      data: formResponse
    })
    if (response.statusText === 'ok') {
      console.log('form submitted')
    }
    // console.log('submission response', response)
    localStorage.removeItem(`hmzform-${slug}`)
  }

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      {status === 'loading' && <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <FallingLines
          color="#4fa94d"
          width="100"
          visible={true}
          ariaLabel='falling-lines-loading'
        />
      </div>}
      {status === 'invalid' && <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <img src={voidIllustration} alt='Form not found' height='50%' align='center' />
        <h2>Form not found</h2>
      </div>}
      {status === 'submitting' && <div style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <FallingLines
          color="#4fa94d"
          width="100"
          visible={true}
          ariaLabel='falling-lines-loading'
        />
      </div>}
      {status === 'loaded' &&
        <Survey
          model={model}
          onComplete={onComplete}
          onValueChanged={doSaveState}
          onCurrentPageChanged={doSaveState}
          onUploadFiles={onUploadFiles}
        />
      }
      {/* <div className="footer">
        <small style={{ marginLeft: "20px" }}>Copyright &copy; {(new Date()).getFullYear()} Hummz</small>
        <a style={{ marginRight: "20px" }} href={`https://www.hummz.com/?utm_source=surveys&utm_medium=web&utm_campaign=${slug || 'hummz-forms'}`}><img src="https://cdn.hummz.it/hummz/powered-by-hummz-h@xxs.png" alt="Powered by Hummz" /></a>
      </div> */}

    </Container>
  );
}

export default SIOForm;

